import React from 'react';
import styles from './Home.module.scss'; 
//import Navbar from '@/components/navbar'

function Home() {
  return (
    <div className={styles.page}>
      {/*  <Navbar /> */}
      {/* <div className={styles.navbar_spacer}></div> */}
      <div className={styles.hero_image}></div>
      <img src="/img/stellaire_logo_big.png" className={styles.hero_logo} />
      <div className={styles.hero_content}>
        <div className={styles.hero_content_toptitle}>UNVEILING OF </div>
        <div className={styles.hero_content_title}>The Stellaire<br/>Experience</div>
         <div className={styles.hero_content_line}></div>
        <div className={styles.hero_content_text}>
          STELLAIRE brings together a community of the culturally curious through exceptional
          encounters and specially curated experiences celebrating the art de vivre (art of living).{' '}
        </div>
        <div className={styles.hero_content_text_small}>BY INVITE ONLY</div>
        <div className={styles.hero_content_text_bottom}>Launching soon!</div>
      </div>
    </div>
  );
}

export default Home;
